import { handleMsgFromNative } from "./handleMsgFromNative";
import { handleMsgFromReact } from "./handleMsgFromReact";

export default function communicateBetweenReactAndNative(
  parameters,
  storeStates,
  setStoreStates
) {
  let options = parameters;

  if (typeof parameters === "string") {
    // means we passing a stringified JSON
    options = JSON.parse(parameters);
  }

  const {
    msgFrom = "react", // native | react
    operation = "",
    content = {},
  } = options;

  if (msgFrom === "react") {
    handleMsgFromReact({
      operation,
      content,
      storeStates,
      setStoreStates,
    });
  } else {
    handleMsgFromNative({
      operation,
      content,
      storeStates,
      setStoreStates,
    });
  }
}
