import React, { createContext, useContext, useEffect, useState } from "react";
import { useCompRendering } from "./CompRenderingContext";
import communicateBetweenReactAndNative from "../utils/communicateBtwReactAndNative/communicateBetweenReactAndNative";

const GlobalStoreContext = createContext();
export const useGlobalStoreContext = () => useContext(GlobalStoreContext);

export const GlobalStoreProvider = ({ children }) => {
  const { setStates: setCompRendringStates } = useCompRendering();
  const [globalStates, setGlobalStates] = useState({
    cameraAccess: false,
  });

  const [storeStates, setStoreStates] = useState({
    defaultHub: null,
    defaultLocation: null,
    navHeadline: {
      heading: "",
      subHeading: "",
      component: null,
    },
    expoToken: "",
    deviceToken: "",
    lastLocationUpdate: null,
    currentLocation: null,
    isGoogleMapsScriptLoaded: false,
    santaEarningsData: {
      // will be present on the initial load of the app.
      // it will contain the current week and last 3 weeks data on the first load.
      weeklyEarnings: [
        // {
        //   week: 32,
        //   ...data
        // },
      ],
    },
    notificationStatus: "",
    FCMToken: "",
    serviceableCities: {},
    santaAppCredentials: {
      installedAppVersion: null,
    },
    deviceLocationInfo: {
      lastFetched: null,
      status: "notAsked",
      location: {
        lat: null,
        lng: null,
      },
    },
  });

  // this is the state for the santa team details
  const [santaTeam, setSantaTeam] = useState({
    team: [],
  });

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(JSON.stringify("reactSiteMounted"));

    window.communicateBetweenReactAndNative = (p) =>
      communicateBetweenReactAndNative(p);

    window.setAppVersion = (version) => {
      setStoreStates((p) => ({
        ...p,
        santaAppCredentials: {
          ...p.santaAppCredentials,
          installedAppVersion: version,
        },
      }));
    };

    window.showMsg = (message, type, callBackFunctionOnCross, options) => {
      // in order to show images, message: img url , type: 'img'
      setCompRendringStates((p) => ({
        ...p,
        warningModel: {
          msg: message,
          rightBtnText: "Ok",
          isRender: true,
          type: type || "",
          callBackFunctionOnCross: callBackFunctionOnCross || null,
          options: options || {},
        },
      }));
    };

    window.setNotificationStatus = (status) => {
      setStoreStates((p) => ({
        ...p,
        notificationStatus: status,
      }));
    };

    window.setFCMToken = (token) => {
      setStoreStates((p) => ({
        ...p,
        FCMToken: token,
      }));
    };

    overrideConsoleLog();
    // eslint-disable-next-line
  }, []);

  function extractDataFromMessage(message) {
    const startMarker = "datastart=";
    const endMarker = "dataend";

    const startIndex = message.indexOf(startMarker);
    const endIndex = message.indexOf(endMarker);

    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
      try {
        const dataString = message.substring(
          startIndex + startMarker.length,
          endIndex
        );
        const validJsonString = dataString.replace(/'/g, '"');

        const data = JSON.parse(validJsonString);

        // alertvalidJsonString);
        // const data = JSON.parse(validJsonString);
        //  alert(data);
        return data;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }

    return null;
  }

  function overrideConsoleLog() {
    const originalConsoleLog = console.log;

    console.log = function (message) {
      if (
        message &&
        typeof message === "string" &&
        message.includes("reactNativeMessage")
      ) {
        const extractedData = extractDataFromMessage(message);
        if (extractedData) {
          if (extractedData?.lat) {
            setStoreStates((p) => ({
              ...p,
              currentLocation: extractedData,
            }));
          }
          const keysArray = Object.keys(extractedData).map((key) =>
            String(key)
          );
          // window.alert("Keys Array:", ...keysArray);
          // Trigger window.alert with extracted data
          // window.alert("yes with data: " + typeof extractedData);
          if (keysArray.includes("cameraPermission"))
            // window.alert("data" + extractedData[keysArray[0]]);
            setGlobalStates((p) => ({
              ...p,
              cameraAccess:
                extractedData[keysArray[0]] === "granted" ? true : false,
            }));
        } else {
          // Trigger window.alert without data
          window.alert("yes " + message);
        }
      }

      originalConsoleLog.apply(console, arguments);
    };
  }

  const sendMessageToNative = (props) => {
    const message = {
      ...props,
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  };

  const handleVibrate = () => {
    sendMessageToNative({
      action: "",
      trigger: "vibration",
      impactLevel: "light",
    });
  };
  window.handleVibrate = (impactLevel) => {
    sendMessageToNative({
      action: "",
      trigger: "vibration",
      impactLevel: impactLevel || "light",
    });
  };

  window.sendMessageToNative = (props) => {
    const message = {
      ...props,
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  };

  window.setExpoToken = (token) => {
    setStoreStates({
      ...storeStates,
      expoToken: token,
    });
  };

  window.setDeviceToken = (token) => {
    setStoreStates({
      ...storeStates,
      deviceToken: token,
    });
  };

  window.copyContent = (content, msg) => {
    window.postMessageRW({
      action: "copyContent",
      content: content,
    });
    window.toast(msg);
  };

  window.openUrl = (url) => {
    sendMessageToNative({
      action: "openUrl",
      url: url,
    });
  };

  window.updateLocation = () => {
    sendMessageToNative({
      action: "updateLocation",
    });
  };

  window.updateSantaId = (santaId) => {
    sendMessageToNative({
      action: "updateSantaId",
      santaId: santaId,
    });
  };

  return (
    <GlobalStoreContext.Provider
      value={{
        globalStates,
        setGlobalStates,
        sendMessageToNative,
        handleVibrate,
        storeStates,
        setStoreStates,
        santaTeam,
        setSantaTeam,
      }}
    >
      {children}
    </GlobalStoreContext.Provider>
  );
};

export default GlobalStoreContext;
