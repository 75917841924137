import React from "react";

export default function UpdatePage() {
  const handleUpdate = () => {
    window.open("https://ebo.onelink.me/Gy1l/cg175q7o", "_blank");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-t from-blue-300 to-white  p-8">
      {/* Brand Name */}
      <div className="flex-col items-end mb-6">
        <span
          className="text-[5rem] font-bold text-[#2136d4]"
          style={{ fontFamily: "'Josefin Sans', sans-serif" }}
        >
          ebo
        </span>
        <span
          className="text-lg font-semibold -mt-[2rem] text-[#2136d4]"
          style={{ fontFamily: "'Josefin Sans', sans-serif" }}
        >
          santa
        </span>
      </div>

      {/* Heading & Description */}
      <h2 className="mb-2 font-semibold text-base text-center">
        New Update Is Available
      </h2>
      <p className="mb-6 text-sm text-center text-gray-500 max-w-lg">
        A brand new version of the ebo Santa app is available. Please update
        your app to use all of our amazing features.
      </p>

      {/* Update Button */}

      <button
        onClick={handleUpdate}
        className="px-6 py-3 text-base w-full font-semibold text-white transition-all duration-300 rounded-md bg-gradient-to-r from-[#2136d4] to-blue-600 hover:opacity-90"
      >
        Update Now!
      </button>
    </div>
  );
}
