import React from "react";
import { batchedSantaApi } from "../../../api/santaApi";

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  async componentDidCatch(error, info) {
    const serializedError = {
      name: error?.name,
      message: error?.message,
      stack: error?.stack,
      data: error?.data,
    };

    await batchedSantaApi([
      {
        operation: "addNewErrorLog",
        data: {
          serializedError: serializedError,
          body: {
            santaId: localStorage.getItem("SANTA_ID") || null,
          },
        },
      },
    ]);
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
